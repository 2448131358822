import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/CompanyProfile',
    name: 'CompanyProfile',
    component: () => import('../views/CompanyProfile.vue')
  },
  {
    path: '/CreatorCommunity',
    name: 'CreatorCommunity',
    component: () => import('../views/CreatorCommunity.vue')
  }
  ,
  {
    path: '/ServicesFeatures',
    name: 'ServicesFeatures',
    component: () => import('../views/ServicesFeatures.vue')
  }
  ,
  {
    path: '/Through',
    name: 'Through',
    component: () => import('../views/Through.vue')
  }
  ,
  {
    path: '/UserGuide',
    name: 'UserGuide',
    component: () => import('../views/UserGuide.vue')
  }
  ,
  {
    path: '/Appointment',
    name: 'Appointment',
    component: () => import('../views/Appointment.vue')
  }
  ,
  {
    path: '/EnquireNow',
    name: 'EnquireNow',
    component: () => import('../views/EnquireNow.vue')
  }
  ,
  {
    path: '/Meta',
    name: 'Meta',
    component: () => import('../views/Meta.vue')
  }
  ,
  {
    path: '/Ar',
    name: 'Ar',
    component: () => import('../views/Ar.vue')
  }
  ,
  {
    path: '/Sdk',
    name: 'Sdk',
    component: () => import('../views/Sdk.vue')
  }
  ,
  {
    path: '/Service',
    name: 'Service',
    component: () => import('../views/Service.vue')
  }
  ,
  {
    path: '/HardwareCode',
    name: 'HardwareCode',
    component: () => import('../views/HardwareCode.vue')
  }
]

const router = new VueRouter({
  routes
})

// 跳转后返回顶部
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

export default router
