<template>
  <div id="app">
    <nav>
      <div class="logo">
        <router-link to="/" @click.native="close_menu"><img src="./assets/images/logo.png"></router-link>
      </div>
      <div class="top-content">
        <a href="https://play.google.com/store/apps/details?id=com.fos.metaverse.overseas&pli=1" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-cloud-arrow-down" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z" />
            <path
              d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
          </svg>
        </a>
        <router-link to="/Appointment?productId=b384edd2-7707-11ee-b86f-00163e2ab162">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-bag-heart-fill"
           viewBox="0 0 16 16"><path d="M11.5 4v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 
           0 0 2-2V4h-3.5ZM8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1Zm0 6.993c1.664-1.711 5.825 
           1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z"/>
          </svg>
        </router-link>
        <a href="javascript:;" @click="open_menu" v-if="isShowMenu == false">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-justify" 
          viewBox="0 0 16 16"><path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 
          0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 
          0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
          </svg>
        </a>
        <a href="javascript:;" @click="close_menu" v-if="isShowMenu == true">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 
          1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>
        </a>
      </div>
    </nav>
    <div :class="['menu',{'open-menu':isShowMenu == true}]">
      <div><router-link to="/Through">Product introduction</router-link></div>
      <div><router-link to="/CompanyProfile">Company overview</router-link></div>
      <div><router-link to="/ServicesFeatures">Services and features</router-link></div>
      <div><router-link to="/CreatorCommunity">Creators community</router-link></div>
    </div>
    <router-view/>
    <footer>
      <div class="footer-floor1">
        <div class="footer-floor1-panel1">
          <div><router-link to="/CompanyProfile">About us</router-link></div>
          <div><router-link to="/Ar">Future AR</router-link></div>
          <div><router-link to="/Sdk">Future Developer Alliance</router-link></div>
        </div>
        <div class="footer-floor1-panel2">
          <div><router-link to="/Appointment?productId=b384edd2-7707-11ee-b86f-00163e2ab162" class="t-btn wit-btn">Book now</router-link></div>
          <div><router-link to="/EnquireNow" class="t-btn wit-btn">Inquire now</router-link></div>
        </div>
     </div>
     <div class="footer-floor2">
   
     </div>
     <div class="footer-floor3">
  
    </div>
    </footer>
  </div>
</template>

<style lang="less">
@font-face {
  font-family: "hyyakuheiw";
  src: url('./font-familys/hyyakuheiw.ttf')
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
  z-index: -10;
  height: auto;
  margin: auto;
}

body {
  position: relative;
  z-index: -100;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  width: 0;
}

nav {
  width: 100%;
  height: 3rem;
  background-color: black;
  opacity: 0.8;
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  .logo{
  height: 1.5rem;
  width: auto;

    img{
        width: auto;
        height: 100%;
    }
  }

  .top-content{
    display: flex;
    justify-content: right;
    align-items: center;
    column-gap: 1.5rem;

    a{
      color: #878787;

      &.router-link-exact-active {
        color: white;
      }
    }

    svg{
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}

.menu{
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 50;
  top: -100vh;
  left: 0;
  background-color: black;

  padding-top: 4rem;
  transition: 1s;

  div{
    // border: 1px solid red;
    width: 90vw;
    height: 7rem;
    line-height: 7rem;
    text-align: center;
    margin: auto;
    border-bottom:1PX solid #212121;
  }

  a{
    color: #bebaba;
    font-size: 1.5rem;
    text-decoration: none;

    &.router-link-exact-active {
      color: white;
    }
  }

}

.open-menu{
  top: 0;
}


.t-btn{
  text-decoration: none;
  color: white;
  border-radius: 50px;
  padding: 2px 10px;
  min-width: 120px;
}

.trans-btn{
  border: 1px solid white;
  padding: 5px 20px;
}

.change-btn{
  border: 1px solid white;
  padding: 8px 30px;
}

.wit-btn{
  border: 1px solid white;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 8rem;
  // height: 2rem;
  // padding: 2px 5px;
  //           min-width: 120px;
}

footer{
  background-color: #111111;
  color: #878787;
  text-align: left;
  padding: 2rem 0;

  .footer-floor1-panel1{
    // border: 1px solid gold;
    
    div{
      // border: 1px solid red;
      padding-left: 3rem;
      width: auto;
      height: 2.5rem;
      line-height: 2.5rem;
    }
  }

  .footer-floor1-panel2{
    // border: 1px solid gold;
    padding-top: 3rem;
    
    div{
      // border: 1px solid red;
      padding-left: 3rem;
      width: auto;
      height: 3rem;
      line-height: 3rem;
    }
  }

  a{
    color: #878787;
    text-decoration: none;

    &.router-link-exact-active {
      color: white;
      font-weight: bold;
    }
  }
}

.trunk {
  position: relative;
  z-index: -5;
  width: 100%;
  min-height: 100vh;
}

.floor {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: -1;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

}

.bottom-shadow {
  background-image: url('./assets/images/video-buttom.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0.9;
}

</style>
<script>
export default {
  data() {
    return {
      isShowMenu:false
    }
  },
  created() {
    this.windowsChange();
  },
  watch:{
    '$route':{
      handler(to, from){
        if(to.name != from.name){
          this.close_menu();
        }
      }
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowsChange();
    }
    window.addEventListener("scroll", this.close_menu);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.close_menu);
  },
  methods: {
    open_menu:function(){
      this.isShowMenu = true;
    },
    close_menu:function(){
      this.isShowMenu = false;
    },
    windowsChange: function () {
      var cw = document.body.clientWidth;
      if (cw >= 850) {
        window.location.href = 'https://www.metamig.top/'
      }
    }
  }
}
</script>
